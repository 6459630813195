export default {
    language: 'Language',
    home: 'Home',
    articleArchive: 'Article Archive',
    articleCategory: 'Article Category',
    articleTag:'Article Tag',
    sponsor: 'Sponsor',
    moment: 'Moment',
    forum: 'Forum',
    say: 'Say',
    tipsoon: 'Tipsoon',
    navigation: 'Navigation',
    hot: 'Hot',
    message: 'Message',
    friendLink: 'FriendLink',
    about: 'About',
    sourceCode: 'Source Code',
    rms: 'RMS',
    doc: 'Project DOC',
    api: 'API DOC',
    compose: 'Compose',
    publishArticle: 'Publish Article',
    publishSay: 'Publish Say',
    publishForum: 'Publish Forum',
    noticeList: ["System Notice", "Comment", "Follow", "Like", "Collect", "Chat"],
    loginTips: ["Login to the website to obtain the following benefits", "Engage in interaction and comments", "Publish quality articles"],
    login: 'Log in',
    userCenter: 'User Center',
    logout: 'Log out'

}
