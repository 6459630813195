export default {
    title: 'This site is actually running',
    day: 'day',
    hour: 'h',
    minute: 'm',
    second: 's',
    pv: 'pv',
    uv: 'uv'

}
